<template>
    <div>
        <div class="blur-screen" v-if="storeState.isLoading"></div>
        <Loading
        v-bind:active="storeState.isLoading"
        v-bind:can-cancel="false"
        v-bind:is-full-page="true"
        v-bind:color="loaderColor"
        >
        </Loading>
        <div class="loading-msg-div row justify-content-around">
            <span class="loading-msg col-10" v-if="storeState.isLoading">
                We are on the blockchain it may take a while, be patient….
            </span>
        </div>
    </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

import { store } from "../classes/Store";

export default {
    name: "Loader",
    components: {
        Loading,
    },
    data: function () {
        return {
            loaderColor: "#FF1FE5",
            storeState: store.state,
        }
    }
}
</script>
<style scoped>
.blur-screen {
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: 200%;
    min-height: 200%;
    backdrop-filter: blur(2px);
    z-index: 900;
}
.loading-msg-div {
    z-index: 10000;
    position: fixed;
    top: 55%;
    width: 100%;
}
.loading-msg {
    font-size: larger;
    color: #FF1FE5;
    text-align: center;
    width: 80%;
}
</style>