<template>
   <div>
      <section id="team" class="py-15 bg-team bg-no-repeat bg-top bg-contain">
         <div class="container text-white flex flex-col">
            <div class="flex mt-17.5 lg:mt-25 flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-[125px] lg:items-start">
               <div id="faq" class="lg:flex-1">
                  <h3 class="font-serif text-5.5 lg:text-7 font-bold">
                     Frequently Asked Questions                    
                  </h3>
                  <div class="my-5 flex flex-col space-y-3.75">
                     <div>
                        <input id="faq-0" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-0" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              I can’t find my tokens. Why?
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 Please reach out to our Telegram wallet support line for assistance:  <a href="https://t.me/xxxPleasurenet_WalletSupport">t.me/xxxPleasurenet_WalletSupport</a>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <input id="faq-1" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-1" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              Where can I buy $NSFW?
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 On QuickSwap, Flooz.trade or via  <a href="https://pleasurecoin.com/buy">pleasurecoin.com/buy</a>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <input id="faq-2" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-2" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              I can’t find $NSFW on Quick Swap, why?   
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 Copy &amp; paste the contract address into QS and it will appear, then import it: 0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <input id="faq-3" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-3" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              Why do I have more than one kind of $NSFW token?
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 We migrated in May ‘21, so everyone was airdropped new token, just hide the old ones. If you aren’t sure which ones are correct, reach out to our Telegram Wallet support: <a href="https://t.me/xxxPleasurenet_WalletSupport">t.me/xxxPleasurenet_WalletSupport</a>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <input id="faq-4" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-4" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              I want to become a creator on your platform. How do I go about this?  
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 Apply to be a creator on <a href="https://nft.xxxnifty.com/my-account/">xxxnifty.com</a>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <input id="faq-5" type="radio" name="faq" class="hidden">
                        <div class="flex items-start space-x-2.5">
                           <img src="../assets/plus.svg" alt="plus" width="12" height="12" class="mx-1">
                           <div>
                              <label for="faq-5" class="flex items-center space-x-2.5 text-3.5 lg:text-base hover:cursor-pointer select-none">
                              I’m a creator and have questions.
                              </label>
                              <p class="duration-300 overflow-hidden max-h-0 prev-checked:max-h-[500px] transition-all text-3.5 lg:text-base">
                                 Creator Support: <a href="https://help.xxxnifty.com/">help.xxxnifty.com</a>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <p class="text-3.5 lg:text-base">
                     Do you have a question that is not listed here?<br>
                     Please <a href="https://xxxnifty.com/contact-us" target="_blank" class="underline">contact</a> us.
                  </p>
               </div>
               <div class="lg:flex-1">
                  <h3 class="font-serif text-5.5 lg:text-7 font-bold">
                     Find us on                    
                  </h3>
                  <div class="mt-5 grid grid-cols-2 gap-1.25 text-3.5 lg:text-base">
                     <a href="https://t.me/pleasurecoin_crypto" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/telegram-plane.svg" alt="Telegram" width="24" height="24" class="mx-1">
                     <span>
                     Telegram
                     </span>
                     </a>
                     <a href="https://discord.gg/GCHkdY6fnF" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/discord.svg" alt="Discord" width="24" height="24" class="mx-1">
                     <span>
                     Discord
                     </span>
                     </a>
                     <a href="https://twitter.com/Pleasure_coin" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/twitter.svg" alt="Twitter" width="24" height="24" class="mx-1">
                     <span>
                     Twitter
                     </span>
                     </a>
                     <a v-show="false" href="https://www.instagram.com/x.xxnifty/" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/instagram.svg" alt="Instagram" width="24" height="24" class="mx-1">
                     <span>
                     Instagram
                     </span>
                     </a>
                     <a href="https://polygonscan.com/token/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/bscscan.svg" alt="PolygonScan" width="24" height="24" class="mx-1">
                     <span>
                     PolygonScan
                     </span>
                     </a>
                     <a v-show="false" href="https://coinmarketcap.com/currencies/xxxnifty/" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/coinmarketcap.svg" alt="CoinMarketCap" width="24" height="24" class="mx-1">
                     <span>
                     CoinMarketCap
                     </span>
                     </a>
                     <a v-show="false" href="https://www.coingecko.com/en/coins/xxxnifty" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/coingecko.svg" alt="CoinGecko" width="24" height="24" class="mx-1">
                     <span>
                     CoinGecko
                     </span>
                     </a>
                     <a href="https://polygon.poocoin.app/tokens/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/poocoin.svg" alt="Poocoin" width="24" height="24" class="mx-1">
                     <span>
                     Poocoin
                     </span>
                     </a>
                     <a href="https://www.flooz.trade/wallet/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/flooz.svg" alt="Flooz.trade" width="24" height="24" class="mx-1">
                     <span>
                     Flooz.trade
                     </span>
                     </a>
                     <a href="https://pleasurely.com" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/pleasurely.svg" alt="Pleasurely" width="24" height="24" class="mx-1">
                     <span>
                     Pleasurely
                     </span>
                     </a>
                     <a href="https://xxxnifty.com" target="_blank" class="px-4 h-12.5 lg:h-15 flex items-center rounded-2.5 transluscent-social space-x-4 hover:bg-opacity-[0.13] transition-colors">
                     <img src="../assets/nifty.svg" alt="xxxNifty" width="24" height="24" class="mx-1">
                     <span>
                     xxxNifty
                     </span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <footer class="bg-brand-dark-text pt-12.5 pb-10">
         <div class="container grid grid-cols-1 md:grid-cols-3">
            <div>
               <a href="/">
               <img src="../assets/logo-nsfwpay-glow.png" alt="NSFW" class="w-[105px]">
               </a>
               <h3 class="font-bold text-white text-opacity-30 font-serif text-5.5 md:text-7 w-2/3 my-6">
                  The future token for explicit content payments
               </h3>
            </div>
            <div class="flex flex-col md:items-center">
               <div>
                  <h4 class="text-sm md:text-base font-medium text-white mt-6">
                     Connect
                  </h4>
                  <nav class="flex flex-col space-y-1 mt-2">
                     <a href="https://help.xxxnifty.com" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Knowledge base
                     </a>
                     <a href="https://t.me/pleasurecoin_crypto" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Telegram
                     </a>
                     <a href="https://discord.gg/GCHkdY6fnF" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Discord
                     </a>
                     <a href="https://twitter.com/Pleasure_coin" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Twitter
                     </a>
                     <a v-show="false" href="https://www.instagram.com/x.xxnifty/" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Instagram
                     </a>
                     <a href="https://polygonscan.com/token/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     PolygonScan
                     </a>
                     <a v-show="false" href="https://coinmarketcap.com/currencies/xxxnifty/" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     CoinMarketCap
                     </a>
                     <a v-show="false" href="https://www.coingecko.com/en/coins/xxxnifty" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     CoinGecko
                     </a>
                     <a href="https://polygon.poocoin.app/tokens/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Poocoin
                     </a>
                     <a href="https://www.flooz.trade/wallet/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Flooz.trade
                     </a>
                     <a href="https://pleasurely.com" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     Pleasurely
                     </a>
                     <a href="https://xxxnifty.com" target="_blank" class="self-start text-sm text-white text-opacity-30 hover:underline hover:text-opacity-100 transition-colors">
                     xxxNifty
                     </a>
                  </nav>
               </div>
            </div>
            <div>
               <h4 class="text-sm md:text-base font-medium text-white mt-6">
                  Contact address            
               </h4>
               <nav class="flex flex-col space-y-1 mt-2">
                  <span class="self-start text-sm md:text-3.5 text-white break-all text-opacity-30">
                  0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19
                  </span>
                  <span class="self-start text-sm md:text-3.5 text-white break-all text-opacity-30">
                  <a href="./Audit_NSFWStaking.pdf">Security Audit</a>
                  </span>
               </nav>
            </div>
            <span class="inline-flex mt-7.5 text-[10px] md:text-[12px] text-white text-opacity-30 md:text-opacity-80">
            © Pleasurecoin 2024 - All rights reserved.
            </span>
         </div>
      </footer>
   </div>
</template>

<script>
  export default {
    mounted() {
      //let liveCoinScript = document.createElement('script')
      //liveCoinScript.setAttribute('src', 'https://www.livecoinwatch.com/static/lcw-widget.js')
      //document.head.appendChild(liveCoinScript)
    },
  }
</script>
<style lang="scss">
.transluscent-social {
   background-color: rgba(0,0,0,0.05);
}
.transluscent-social:hover {
   color: white;
   background-color: rgba(0,0,0,0.10);
}
</style>