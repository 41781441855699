<template>
  <div class="contract-card shadow">
    <ConfirmDialog ref="confirmDialog" />
    <PopupDialog ref="popupDialog" />
    <div class="contract-header">
      <div>
        <h5 class="poppins-bold text-2xl col-10 mx-auto">{{ contract.title }}</h5>
      </div>
    </div>
    <div v-if="storeState.connected && !contract.userData.data.isAdmin" class="contract-body">
      <div class="conditional-field d-flex justify-content-between" v-if="stakedBalance > 0">
      </div>
        <label class="xxxnifty-label">Staked Balance : {{ stakedBalanceFormatted }}</label>
      <div class="conditional-field d-flex justify-content-between" v-if="stakedBalance > 0 && !unlockRequested">
        <label class="xxxnifty-stealth-label">You can unlock until: {{ minimalUnlockDateFormatted }}</label>
        <a v-on:click="unlock" class="btn xxxnifty-stealth-btn">
          Unlock
        </a>
      </div>
      <div class="conditional-field d-flex justify-content-between" v-if="stakedBalance > 0 && unlockRequested">
        <label class="xxxnifty-stealth-label">Unlock requested, available at: {{ unlockDateFormatted }}</label>
      </div>
      <div>
        <div class="row justify-content-around" v-if="!contract.userData.data.depositFreeze">
          <input
            class="col-10 nsfw-input"
            v-model="depositAmount"
            type="number"
            min="100000"
            max="10000000000"
          />
        </div>
        <div class="indications" v-if="!contract.userData.data.depositFreeze">
          <label v-if="contract.userData.data.allowance > 0">
            You have approved an amount of {{ allowanceFormatted }} to be
            staked, please make the final transfer.
          </label>
          <label v-else>
            You have to approve the amount you want to stake before making
            the final deposit, please enter the amount and click Approve
            amount
          </label>
        </div>
        <div class="row justify-content-around">
          <a v-if="contract.userData.data.depositFreeze" v-on:click="false" class="card-btn btn col-10 disabled">
            Staking frozen
          </a>
          <a v-else-if="contract.userData.data.allowance > 0" v-on:click="deposit" class="card-btn btn pink-gradient col-10">
            Stake NSFW
          </a>
          <a v-else v-on:click="approve" class="card-btn btn pink-gradient col-10">
            Approve
          </a>
        </div>
        <div class="d-flex align-items-baseline flex-row-reverse conditional-field" v-if="!contract.userData.data.depositFreeze && contract.userData.data.allowance > 0">
          <a v-on:click="approve" class="btn xxxnifty-stealth-btn">
            Approve more
          </a>
          <label class="xxxnifty-stealth-label">If needed </label>
        </div>
      </div>
      <div v-if="unlockedBalance > 0">
        <div class="conditional-field d-flex justify-content-between">
          <label class="xxxnifty-label">Available for withdraw : {{ unlockedBalanceFormatted }}</label>
          <a v-on:click="withdraw" class="btn pink-gradient">Withdraw</a>
        </div>
      </div>
      <div v-if="rewardsBalance > 0">
        <label class="reward-header">Reward</label>
        <div class="row justify-content-around">
          <a v-on:click="claimReward" class="card-btn btn pink-gradient col-10"> Claim {{ rewardsBalanceFormatted }}</a>
        </div>
        <!-- <div class="d-flex justify-content-center"><label class="reward-text">- OR -</label></div>
        <div class="row justify-content-around">
          <a v-on:click="claimRewardNSFW" class="card-btn btn pink-gradient col-10"> Claim as {{ rewardsBalanceNSFWFormatted }}</a>
        </div> -->
      </div>
    </div>
    <div v-else>
      <div v-if="storeState.connected" class="contract-body">
        <label class="mb-2">Total staked balance : {{ totalStakedBalanceFormatted }}</label><br>
        <div v-if="totalStakedBalance > 0">
          <div class="row justify-content-around" v-if="!contract.userData.data.depositFreeze">
            <input class="nsfw-input col-10" v-model="rewardAmount" type="number" />
          </div>
          <div class="indications" v-if="!contract.userData.data.depositFreeze">
            <label v-if="contract.userData.data.allowance > 0">
              You have approved an amount of {{ allowanceFormatted }} to be
              staked, please make the final transfer.
            </label>
            <label v-else>
              You have to approve the amount you want to stake before making
              the final deposit, please enter the amount and click Approve
              amount
            </label>
          </div>
          <div class="row justify-content-around">
            <a v-if="contract.userData.data.depositFreeze" v-on:click="false" class="card-btn btn col-10 disabled">
              Staking frozen
            </a>
            <a v-else-if="contract.userData.data.allowance > 0" v-on:click="sendReward" class="card-btn btn lila-straigth col-10">
              Send NSFW
            </a>
            <a v-else v-on:click="approve" class="card-btn btn lila-straigth col-10">
              Approve
            </a>
          </div>
        </div>
        <div v-else>
          <div class="row justify-content-around">
            <a v-on:click="initializePool" class="card-btn btn pink-gradient col-10">Initialize Pool</a>
          </div>
        </div>
        <div class="d-flex align-items-baseline flex-row-reverse conditional-field" v-if="!contract.userData.data.depositFreeze && contract.userData.data.allowance > 0">
          <a v-on:click="approve" class="btn xxxnifty-stealth-btn">
            Approve more
          </a>
          <label class="xxxnifty-stealth-label">If needed </label>
        </div>
      </div>
      <div class="contract-body">
        <form class="mt-3 mb-3">
        <button
          id="connectButtonWeb3"
          type="button"
          class="btn lila-straigth h-10.5 col-12"
          v-on:click="connect()"
          v-if="!storeState.connected"
        >Connect</button>
        </form>
        <p>{{ contract.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../classes/Store";

import ConfirmDialog from "./ConfirmDialog.vue";
import PopupDialog from "./PopupDialog.vue";

export default {
  name: "ContractBox",
  components: {
    ConfirmDialog,
    PopupDialog,
  },
  props: {
    contract: {},
  },
  data: function () {
    return {
      storeState: store.state,
      depositAmount: {},
      rewardAmount: {},
      timer: '',
    };
  },
  created: function() {
    var c = this.contract
    this.timer = setInterval(function() {
      c.getData()
    }, 5000);
  },
  methods: {
    connect: function() { this.$emit('connectClick'); },
    formatNumber: function (value, unit, minPrecision = 1, maxPrecision = 10) {
      return value.toLocaleString(["en-US"], {
        localeMatcher: "lookup",
        style: "decimal",
        minimumIntegerDigits: 1,
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision//,
        //minimumSignificantDigits: minPrecision,
        //maximumSignificantDigits: maxPrecision
      }) + " " + unit;
    },

    approve: async function () {
      if (this.depositAmount > this.contract.userData.data.allowance) {
        if (this.depositAmount >= 100000 && this.depositAmount <= 1000000000) {
          this.contract.approve(this.depositAmount);
          this.contract.getData();
        } else if (this.rewardAmount >= 100000 && this.rewardAmount <= 1000000000) {
          this.contract.approve(this.rewardAmount);
          this.contract.getData();
        } else {
          await this.$refs.popupDialog.show({
            message: "Amount have to be between 1 000 000 and 1 000 000 000",
          });
        }
      } else {
        await this.$refs.popupDialog.show({
          message:
            "The new approved amount should be above the approved amount of " +
            this.allowanceFormatted + ".",
        });
      }
    },
    claimReward: async function () {
      if (this.rewardsBalance > 0) {
        this.contract.claimReward();
        this.contract.getData();
      } else {
        await this.$refs.popupDialog.show({
          message: "You don't have any rewards to claim",
        });
      }
    },
    claimRewardNSFW: async function () {
      if (this.rewardsBalance > 0) {
        this.contract.claimRewardAsNsfw();
        this.contract.getData();
      } else {
        await this.$refs.popupDialog.show({
          message: "You don't have any rewards to claim",
        });
      }
    },
    deposit: async function () {
      if (this.depositAmount <= this.contract.userData.data.allowance) {
        if (this.depositAmount >= 100000 && this.depositAmount <= 1000000000) {
          this.contract.deposit(this.depositAmount);
          this.contract.getData();
        } else {
          await this.$refs.popupDialog.show({
            message:
              "Amount should be between 1 000 000 and 1 000 000 000.",
          });
        }
      } else {
        await this.$refs.popupDialog.show({
          message:
            "Amount should be below or equal to the approved amount of " +
            this.allowanceFormatted + ".",
        });
      }
    },
    unlock: function () {
      this.contract.requestUnlock();
      this.contract.getData();
    },
    withdraw: function () {
      this.contract.withdraw();
      this.contract.getData();
    },
    initializePool: async function () {
      if (this.contract.userData.data.isAdmin) {
        const ok = await this.$refs.confirmDialog.show({
          title: undefined,
          message:
            "Are you sure that you want to send initialize the pool",
          okButton: "Yes",
        });
        if (ok) {
          this.contract.sendReward(0);
          this.contract.getData();
        } else {
          await this.$refs.popupDialog.show({
            message: "You chose not to send reward. Doing nothing now.",
          });
        }
      } else {
        await this.$refs.popupDialog.show({
          message: "You must be admin to initialize pools",
        });
      }
    },
    sendReward: async function () {
      if (this.contract.userData.data.isAdmin) {
        if ((this.rewardAmount > 0 && this.totalStakedBalance > 0) ||
          (this.rewardAmount == 0 && this.totalStakedBalance == 0))
        {
          const ok = await this.$refs.confirmDialog.show({
            title: undefined,
            message:
              "Are you sure that you want to send " +
              this.rewardAmount +
              " NSFW ?",
            okButton: "Yes",
          });
          if (ok) {
            try {
              await this.contract.sendReward(this.rewardAmount);
              this.rewardAmount = null;
              this.contract.getData();
            } catch (exception) {
              alert(exception.message);
              throw new Error('Transaction failed');
            }
          } else {
            await this.$refs.popupDialog.show({
              message: "You chose not to send reward. Doing nothing now.",
            });
          }
        } else {
        await this.$refs.popupDialog.show({
          message: "If no tokens are staked, you must send 0 NSFW\nIf token are staked, you must send more than 0 NSFW",
        });
        }
      } else {
        await this.$refs.popupDialog.show({
          message: "You must be admin to send rewards",
        });
      }
    },
  },
  computed: {
    unlockDateFormatted: function () {
      const date = new Date((parseInt(this.contract.userData.data.unlockDate.toString(), 10) + 2 * 60 * 60) * 1000);
      return [date.getUTCDate().toString().padStart(2, '0'), (date.getUTCMonth()+1).toString().padStart(2, '0'), date.getUTCFullYear()].join('/')
    },
    minimalUnlockDateFormatted: function () {
      const date = new Date((parseInt(this.contract.userData.data.minimalUnlockDate.toString(), 10) - 7 * 24 * 60 * 60) * 1000);
      return [date.getUTCDate().toString().padStart(2, '0'), (date.getUTCMonth()+1).toString().padStart(2, '0'), date.getUTCFullYear()].join('/')
    },
    isUnlockDatePassed: function () {
      return new Date(parseInt(this.contract.userData.data.unlockDate.toString(), 10) * 1000) < Date.now();
    },
    stakedBalance: function () {
      return this.contract.userData.data.stakedBalance;
    },
    stakedBalanceFormatted: function () {
      return this.formatNumber(
        this.contract.userData.data.stakedBalance,
        "NSFW", 0
      );
    },
    allowance: function () {
      return this.contract.userData.data.allowance;
    },
    allowanceFormatted: function () {
      return this.formatNumber(this.contract.userData.data.allowance, "NSFW", 0);
    },
    unlockedBalance: function () {
      if (this.contract.userData.data.emergencyUnlockStatus ) {
        return this.contract.userData.data.unlockedBalance + this.contract.userData.data.stakedBalance
      } else {
        return this.contract.userData.data.unlockedBalance
      }
    },
    unlockedBalanceFormatted: function () {
      if (this.contract.userData.data.emergencyUnlockStatus ) {
        return this.formatNumber(
          this.contract.userData.data.unlockedBalance + this.contract.userData.data.stakedBalance,
          "NSFW", 0
        );
      } else {
        return this.formatNumber(
          this.contract.userData.data.unlockedBalance,
          "NSFW", 0
        );
      }
      
    },
    unlockRequested: function () {
      return this.contract.userData.data.unlockRequested;
    },
    rewardsBalance: function () {
      return this.contract.userData.data.rewardsBalance;
    },
    rewardsBalanceFormatted: function () {
      return this.formatNumber(
        this.contract.userData.data.rewardsBalance,
        "NSFW", 0, 2
      );
    },
    totalStakedBalance: function () {
      return this.contract.totalStakedBalance;
    },
    totalStakedBalanceFormatted: function () {
      return this.formatNumber(
        this.contract.totalStakedBalance,
        "NSFW", 0
      );
    },
    emergencyUnlockStatus: function() {
      return this.contract.userData.data.emergencyUnlockStatus
    }
  },
  watch: {
    allowance(newValue) {
      if (this.depositAmount == null || this.depositAmount == 0) {
        this.depositAmount = newValue
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/index.scss";

.contract-card {
  background-color: white;
  border-radius: 20px;
  margin-bottom: 120px;
  max-width: 360px;
  min-width: 260px;

  .contract-header {
    text-align: center;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 0px;
    border-bottom: 1px solid whitesmoke;
    align-items: center;
  }
  .contract-body {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
  }
}

@media (max-width: 990px) 
{
  .contract-card {
    margin-left:auto;
    margin-right:auto;
    margin-top:60px;
    margin-bottom:0px;
  }
}

@media (max-width: 575px) 
{
  .contract-card {
    margin-left:0px;
    margin-right:0px;
    margin-top:60px;
    margin-bottom:0px;
    max-width: 500px;
    min-width: 260px;
  }
}

.nsfw-input {
  margin-bottom: 20px;
  margin-top: 20px !important;
  border-radius: 20px;
  height: 2.5rem;
  border: solid grey 1px;
}

.indications {
  padding-top: 0px;
  padding-bottom: 10px;
  color: grey;
  font-size: 0.8rem;
}

.conditional-field {
  padding: 10px 0px;
  .xxxnifty-label {
    display: flex;
    align-items: center;
  }
  .xxxnifty-stealth-label {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: grey;
  }
  .xxxnifty-stealth-btn {
    font-size: 0.8rem;
    color: grey;
    border-radius: 0.4rem;
    border: solid grey 1px;
    padding: 5px;
    margin: 10px;
    height: 2rem !important;
  }
  .xxxnifty-stealth-btn:hover {
    background-color: grey;
    color: white;
  }
}

.reward-header {
    font-size: larger;
    margin-top: 10px;
}

.reward-text {
  color: grey;
}

.card-btn {
  height: fit-content !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
</style>