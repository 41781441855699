/* eslint-disable no-undef */

import { getContract, waitForTransaction } from '@wagmi/core';
import UserContractData from '../classes/UserContractData'
import { store } from './Store'

const DEPOSIT_FREEZE = 0xC0;
// const REWARD_FREEZE = 0x30;
// const CLAIM_FREEZE = 0x0C;
// const WITHDRAW_FREEZE = 0x03;

export default class Contract {
  id
  title
  length
  description
  userData

  // Prod
  //contract4WeeksAddress = "0xd8968C6130C462e3dc0bB022C62002dB963335B6";
  //contract12WeeksAddress = "0x84ED758f73C994D61B4a119c06700889C8463F8B";
  // Test Mainnet
  contract4WeeksAddress = "0xAad3B603623032d9941AF26599a256830fba2254";
  contract12WeeksAddress = "0x84ED758f73C994D61B4a119c06700889C8463F8B";
  
  contractAddress;
  contractDecimals = 18;
  contract;
  
  tokenAddress = "0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19";
  //tokenAddress = "0xC50D045AdbeeC91a551499a3d049F2bc4041e853";
  tokenDecimals = 18;
  token;

  constructor(id, title, length, description) {
    this.id = id
    this.title = title
    this.length = length
    this.userData = new UserContractData()
    if (length == 4) {
      this.contractAddress = this.contract4WeeksAddress;
    } else if (length == 12) {
      this.contractAddress = this.contract12WeeksAddress;
    } else {
      throw new Error("Need to set a valid contract length");
    }
    this.description = description
    this.storeState = store.state;
  }

  getContractBase() {
    const ten = 10;
    const base = Math.pow(ten, this.contractDecimals);
    return base;
  }

  getTokenBase() {
    const ten = 10;
    const base = Math.pow(ten, this.tokenDecimals);
    return base;
  }

  buildOption() {
    return {
      account: this.storeState.selectedAccount.address,
      maxPriorityFeePerGas: undefined,
      maxFeePerGas: undefined,
      gas: undefined,
      value: undefined
    }
  }

  async sendTransaction(func, input = []) {
    const option = this.buildOption();
    try {
      option.gas = await this.contract.estimateGas[func](input, option);
  
      const hash = await this.contract.write[func](input, option);
      await waitForTransaction({ hash });
    } catch (exception) {
      alert(exception.message);
      this.storeState.isLoading = false;
      throw new Error('Transaction failed');
    }
  }

  async setContracts() {
    var jsonAbi = require("../resources/NSFWStaking.json");
    this.contract = getContract({
      address: this.contractAddress,
      abi: jsonAbi,
      walletClient: this.storeState.walletClient
    });

    var jsonAbi2 = require("../resources/NSFWTokenTest.json");
    this.token = getContract({
      address: this.tokenAddress,
      abi: jsonAbi2,
      walletClient: this.storeState.walletClient
    });

    this.getData();
  }

  clear() {
    this.userData.clear()
    this.contract = null
    this.token = null
  }

  getData() {
    if (this.contract == null || this.token == null) {
      return;
    }

    this.isAdmin().then((value) => { 
      this.userData.data.isAdmin = value 
      this.getTotalStakedBalance().then((value) => { this.totalStakedBalance = value })
    })
    this.getAllowance().then((value) => { this.userData.data.allowance = value })
    this.getTotalBalance().then((value) => { 
      this.userData.data.stakedBalance = value 
      this.getMinimalUnlockDate().then((value) => { this.userData.data.minimalUnlockDate = value })
    })
    this.getUnlockRequested().then((value) => {
      this.userData.data.unlockRequested = value
      this.getUnlockedDate().then((value) => { this.userData.data.unlockDate = value })
    })
    this.getUnlockedBalance().then((value) => { this.userData.data.unlockedBalance = value })
    this.getUserReward().then((value) => { this.userData.data.rewardsBalance = value })
    this.getEmergencyUnlockStatus().then((value) => { this.userData.data.emergencyUnlockStatus = value })
    this.getCurrentFreeze().then((value) => { this.userData.data.depositFreeze = (value & DEPOSIT_FREEZE) === DEPOSIT_FREEZE })
  }

  getAccount() {
    return this.storeState.selectedAccount?.address;
  }

  async getTotalBalance() {
    const account = this.getAccount();
    const totalBalance = await this.contract.read.getTotalBalance([account]);

    return totalBalance / BigInt(this.getTokenBase());
  }

  async getCurrentFreeze() {
    return await this.contract.read.currentFreeze();
  }

  async deposit(amount) {
    this.storeState.isLoading = true;
    const amountToDeposit = BigInt(amount) * BigInt(this.getTokenBase());
    const account = this.getAccount();
    const nsfwAmount = await this.token.read.balanceOf([account])
    if (nsfwAmount < amountToDeposit) {
      alert('Your wallet only contains ' + nsfwAmount / BigInt(this.getTokenBase()).toString() + ' NSFW, you can\'t stake more than that')
      this.storeState.isLoading = false;
    } else {
      await this.sendTransaction('deposit', [amountToDeposit]);
      this.storeState.isLoading = false;
    }
  }

  async getAllowance() {
    const account = this.getAccount();
    const currentAllowance = await this.token.read.allowance([account, this.contractAddress]);
    return currentAllowance / BigInt(this.getTokenBase());
  }

  async approve(amount) {
    this.storeState.isLoading = true;
    const option = this.buildOption();
    const amountToDeposit = BigInt(amount) * BigInt(this.getTokenBase());
    
    const currentAllowance = await this.token.read.allowance([option.account, this.contractAddress]);
    try {
      if (currentAllowance < amountToDeposit) {
        const hash = await this.token.write.approve([this.contractAddress, amountToDeposit], option);
        await waitForTransaction({ hash });
      
        const tx2 = await this.waitAllowance(this.token, option.account, this.contractAddress, amountToDeposit);
        if (!tx2) {
          alert('Transaction may have failed')
          this.storeState.isLoading = false;
          throw new Error('Failed to approve transaction')
        } 
      }
      this.storeState.isLoading = false;
    } catch {
      alert('Transaction may have failed');
      this.storeState.isLoading = false;
      throw new Error('Failed to approve transaction');
    }
  }

  async waitAllowance(
    contract,
    account,
    to,
    allowanceNeeded,
  ) {
    const needed = BigInt(allowanceNeeded);
    var timesLeft = 30;
    if (timesLeft > 1) {
      const currentAllowance = await contract.read.allowance([account, to]);
      if (needed <= currentAllowance) {    
        return true
      }
      await new Promise((res) => setTimeout(res, 1000))
      timesLeft--;
    }
    return false
  }

  async getUnlockedBalance() {
    const account = this.getAccount();
    const unlockedBalance = await this.contract.read.getUnlockedBalance([account]);
    return unlockedBalance / BigInt(this.getTokenBase());
  }

  async getUnlockedDate() {
    if (this.userData.data.unlockRequested) {
      const account = this.getAccount();
      return await this.contract.read.getUnlockedDate([account]);
    } else {
      return 0;
    } 
  }

  async getMinimalUnlockDate() {  
    if (this.userData.data.stakedBalance > 0) {
      const account = this.getAccount();
      return await this.contract.read.getMinimalUnlockDate([account]);
    } else {
      return 0;
    }
  }

  async getUnlockRequested() {
    const account = this.getAccount();
    return await this.contract.read.getUnlockRequested([account]);
  }

  async withdraw() {
    this.storeState.isLoading = true;
    await this.sendTransaction('withdraw');
    this.storeState.isLoading = false;
  }

  async requestUnlock() {
    this.storeState.isLoading = true;
    await this.sendTransaction('requestUnlock');
    this.storeState.isLoading = false;
  }

  async claimReward() {
    this.storeState.isLoading = true;
    await this.sendTransaction('claimReward');
    this.storeState.isLoading = false;
  }

  async getUserReward() {
    const account = this.getAccount();
    const result = await this.contract.read.getUserReward([account]);
    return result / BigInt(this.getContractBase()); // It is MATIC based not NSFW based
  }

  // ADMIN FUNCTIONS
  async isAdmin() {
    const account = this.getAccount();
    return await this.contract.read.isAdmin([account]);
  }

  async getTotalStakedBalance() {
    if (this.userData.data.isAdmin) {
      const result = await this.contract.read.totalStaked();
      return result / BigInt(this.getTokenBase());
    }
    
    return null;
  }

  async getEmergencyUnlockStatus()
  {
    return await this.contract.read.emergencyUnlockStatus();
  }

  async sendReward(amount) {
    this.storeState.isLoading = true;
    const amountToReward = BigInt(amount) * BigInt(this.getContractBase()); // It is MATIC based not NSFW based

    const option = this.buildOption();
    const input = [amountToReward.toString()];
    try {
      option.gas = await this.contract.estimateGas.sendReward(input, option);
      const hash = await this.contract.write.sendReward(input, option);
      await waitForTransaction({ hash });

      this.storeState.isLoading = false;
    } catch (exception) {
      alert(exception.message);
      this.storeState.isLoading = false;
      throw new Error('Transaction failed');
    }
  }
}