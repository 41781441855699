import { reactive } from "vue";

export const store = {
    state: reactive({
        connected: false,
        selectedAccount: null,
        isLoading: false,
        walletClient: null
    })
}
