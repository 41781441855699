<template>
    <PopupDialog ref="popupDialog" />
</template>

<script>
import { configureChains, createConfig, disconnect, switchNetwork, watchAccount, watchNetwork, watchWalletClient } from '@wagmi/core'
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { polygon, polygonMumbai } from "@wagmi/core/chains";

import { store } from "../classes/Store";

import PopupDialog from "./PopupDialog.vue";

const PROJECT_ID = 'e067098dcb7e3f2df94d41416198648d';

export default {
  name: "Web3Handler",
  props: {
    contracts: {},
  },
  components: {
    PopupDialog,
  },
  data: function () {
    return {
      storeState: store.state,
      web3Modal: null,
      unwatches: [],
      chainName: 'Polygon',
      chain: {}
    };
  },
  mounted() {
    this.chain = [polygon, polygonMumbai].find((c) => c.name === this.chainName)
 
    const { publicClient } = configureChains([this.chain], [w3mProvider({ projectId: PROJECT_ID })]);
    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId: PROJECT_ID, version: 2, chains: [this.chain] }),
      publicClient
    });
    const ethereumClient = new EthereumClient(wagmiConfig, [this.chain]);
    this.web3Modal = new Web3Modal({
      projectId: PROJECT_ID,
      explorerExcludedWalletIds: 'ALL',
      defaultChain: this.chain,
      explorerRecommendedWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4'
      ]
    }, ethereumClient);
    this.unwatches = this.initWatchers()
  },
  methods: {
    init: function () {
      // Check that the web page is run in a secure context,
      // as otherwise MetaMask won't be available
      if (location.protocol !== "https:") {
        alert("You can run this example only over HTTPS connection.");
        return;
      }
    },
    fetchAccountData: async function () {
      
    },

    switchNet: async function() {
      this.$refs.popupDialog.show({
        message: "Network have to be set to Polygon",
      });

      try {
        await switchNetwork({ chainId: this.chain.id })
      } catch (err) {
        console.error(err)

        try {
          // TODO add chain
          await this.storeState.walletClient.addChain({ chain: this.chain });
        } catch (err) {
          console.error(err);
          this.disconnect()
        }
      }
    },
    addToken: async function() {
      if (this.storeState.walletClient == null) {
        return
      }

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        await this.storeState.walletClient.watchAsset({
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: '0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19', // The address that the token is at.
            symbol: 'NSFW', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9840.png', // A string url of the token logo
          },
        });
        this.$refs.popupDialog.show({
          message: "You should see the token in your wallet",
        });
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * Connect wallet button pressed.
     */
    connect: async function () {
      try {
        await this.web3Modal.openModal();
      } catch (e) {
        console.error(e);
        this.storeState.connected = false;
        return;
      }
    },
    initWatchers() {
      // Subscribe to accounts change
      // eslint-disable-next-line no-unused-vars
      const unwatchAccount = watchAccount((account) => {
        if (account.isDisconnected) {
          this.storeState.connected = false;
          this.storeState.selectedAccount = null;
          
          return;
        }

        this.storeState.selectedAccount = account;
        
        // Called on watchWalletClient
        // this.contracts.forEach((value) => {
        //   value.setContracts();
        // });

        this.storeState.connected = account.isConnected;
      })

      const unwatchWalletClient = watchWalletClient({ chainId: this.chain.id }, (walletClient) => {
        this.storeState.walletClient = walletClient;

        if (walletClient != null) {
          this.contracts.forEach((value) => {
            value.setContracts();
          });
        }
      })

      const unwatchNetwork = watchNetwork((network) => {
        if (network.chain?.name !== this.chainName && network.chains.length > 0) {
          this.switchNet();
        }
      })

      return [unwatchAccount, unwatchWalletClient, unwatchNetwork];
    },

    /**
     * Disconnect wallet button pressed.
     */
    disconnect: async function () {
      await disconnect();

      this.storeState.selectedAccount = null;
      this.storeState.walletClient = null;

      // Set the UI back to the initial state
      this.storeState.connected = false;

      // eslint-disable-next-line no-unused-vars
      this.contracts.forEach((value, index) => {
        value.clear();
      });
      
      if (this.web3Modal.closeModal) {
        await this.web3Modal.closeModal();
      }
    },
  },
};
</script>