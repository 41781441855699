<template>
  <div class="vld-parent">
    <Loader />
    <Navigation @connectClick="connect" @disconnectClick="disconnect" @addTokenClick="addToken" />
    <Header />
    <div class="row container justify-content-between py-17.5 lg:py-25">
      <div class="col-12 col-sm-10 col-lg-6">
        <h2 class="lg:px-0 font-serif font-bold text-2xl lg:text-7">
          <mark class="poppins-semibold">What is staking? </mark>
        </h2>
        <div class="lg:px-0 flex flex-col lg:mt-7">
            <p class="lg:text-lg">
            Staking is locking up your tokens for a period of either 4 (frozen) or 12 weeks in order to earn passive income.<br><br>
              </p>
              <ul class="pl-7" style="list-style-type: disc;">
                <li>Staking for 4 weeks will give you an APY of 4%.</li>
                <li>Staking for 12 weeks will give you an APY of 10%.</li>
              </ul>
              <br><br>
          <p class="lg:text-lg">
            <b>$NSFW</b> rewards can be claimed every Monday. Maybe use them to tip our creators on <a href="https://www.pleasurely.com/" target="_blank"><b>Pleasurely</b></a> or buy an NFT on <a href="https://pleasurenifty.com/" target="_blank"><b>PleasureNifty</b></a>!
              <br><br>
              <span class="lg:px-0 font-serif font-bold text-2xl lg:text-7">Staking is as easy as 1, 2, 3.</span>
              <br><br>
              <span class="number-round">1</span> Connect to the site (On Polygon Network)
              <br>
              <span class="number-round">2</span> Choose 4 or 12 Weeks and Enter the amount you want to stake (1 million minimum)
              <br>
              <span class="number-round">3</span> a) Approve the deposit – b) Stake the deposit
              <br><br>
              That’s it! Sit back and enjoy the rewards.
              <br><br>
              <span class="lg:px-0 font-serif font-bold text-2xl lg:text-7">Things to remember:</span>
              <br><br>
              You can stake at any point, but the rewards only begin generating in the following week. So if you start staking on Wednesday of week 1, the 4 week period starts in the following week (week 2) and lasts for either 4 or 12 weeks from then, depending on the pool chosen.
              <br><br>
              You can add to your staking amount, but the staking period resets based on your last deposit. So if you stake for 4 weeks in week 1, but top up in week 2, your 4 weeks period resets and lasts until the end of week 6.
              <br><br>
              If at the end of the staking period, a user doesn’t manually unlock their stake, they will be auto-re-staked for the same period.
              <br><br>
              To unlock the stake at the end of the period, the request must be made using the “Unlock” button any time before the final week. Eg a 4 weeks staking period ends in week 5 - if you don’t want to renew, click the “Unlock” button in week 1-4.
              <br><br>
              Unlock date is written in DD/MM/YYYY format.
              <br><br>
              Once a stake is unlocked, rewards are no longer earned and NSFW can be withdrawn by using the withdraw button.
            </p>
        </div>
      </div>
      <div class="col-12 col-sm-10 col-lg-5">
        <ContractBox
          v-for="contract in contracts"
          v-bind:key="contract.id"
          v-bind:contract="contract"
          @connectClick="connect" 
        />
      </div>
    </div>
    <Web3Handler ref="web3Handler" v-bind:contracts="contracts" />
    <Footer />
  </div>
</template>

<script>
require('events').EventEmitter.defaultMaxListeners = 100

import Navigation from "./components/Navigation.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import ContractBox from "./components/ContractBox.vue";
import Web3Handler from "./components/Web3Handler.vue";
import Loader from "./components/Loader.vue";

import Contract from "./classes/Contract";

import { store } from "./classes/Store";

export default {
  name: "App",
  components: {
    Navigation,
    Header,
    Footer,
    ContractBox,
    Web3Handler,
    Loader,
  },
  methods: {
    connect: async function () {
      this.$refs.web3Handler.connect();
    },
    disconnect: function () {
      this.$refs.web3Handler.disconnect();
    },
    addToken: function() {
      this.$refs.web3Handler.addToken();
    }
  },
  data: function () {
    return {
      contracts: [
        new Contract(1, "12 WEEKS STAKING", 12, "Stake your NSFW for at least 12 weeks. You will be able to withdraw your staked tokens 12 weeks after your last deposit."),
        new Contract(0, "4 WEEKS STAKING", 4, "Stake your NSFW for at least 4 weeks. You will be able to withdraw your staked tokens 4 weeks after your last deposit."),
      ],
      storeState: store.state,
    }
  },
  created: function () {
    document.title = "Staking NSFW"
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$refs.web3Handler.init();
    });
  },
};
</script>

<style lang="scss">
@import "./scss/index.scss";

  html, body {
    margin: 0;
    height: 100%;
    background-color: whitesmoke;
  }
</style>
