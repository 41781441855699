<template>
    <section id="hero" class="bg-hero bg-no-repeat bg-cover bg-center pb-[7.5rem] lg:pb-[200px] pt-[11.25rem] lg:pt-[280px] text-white -mt-15 lg:-mt-20">
        <div class="container">
            <h1 class="poppins-semibold text-10.5 leading-none w-2/3 lg:text-15.5">
                Stake your $NSFW and earn weekly rewards
            </h1>
            <div style="display: flex; flex-direction: row;">
              <div class="mt-7.5 flex items-center space-x-2.5 md:space-x-3.5 pr-7">
                  <a href="https://quickswap.exchange/#/swap?outputCurrency=0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19&swapIndex=0" class="btn lila-straigth buy-btn" target="_blank">Buy on Quickswap</a>
              </div>
              <div class="mt-7.5 flex items-center space-x-2.5 md:space-x-3.5">
                <a href="https://flooz.xyz/trade/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19/polygon/pleasure-coin/nsfw?&utm_source=token-partner-widget-charts&utm_medium=token-page-charts&utm_campaign=flooz-widget" class="btn lila-straigth buy-btn" target="_blank">Buy on Flooz</a>
              </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
@import "../scss/index.scss";


.bg-hero {
	background-image: url(../assets/background-hero.jpeg)
}

.bg-border-swap {
	background-image: url(../assets/border-swap.png)
}

.bg-team {
	// background-image: url(../assets/border-swap.png), linear-gradient(180deg, #fff 0, #fff 30%, #ff31af 60%, #fe5930)
  background-color: #2E0848;
}

.buy-btn {
    display: inline-flex !important;
}

</style>
<script setup lang="ts">
</script>