import { reactive } from "vue";

export default class UserContractData {
    data = reactive({
        isAdmin: false,
        totalStakedBalance: 0,
        allowance: 0,
        stakedBalance: 0,
        unlockedBalance: 0,
        unlockDate: 0,
        rewardsBalance: 0,
        rewardsBalanceNsfw: 0,
        minimalUnlockDate: 0,
        emergencyUnlockStatus: false,
        depositFreeze: false
    })

    constructor() {
    }

    clear() {
        this.data.isAdmin = false
        this.data.totalStakedBalance = 0
        this.data.allowance = 0
        this.data.stakedBalance = 0
        this.data.unlockedBalance = 0
        this.data.unlockDate = 0
        this.data.rewardsBalance = 0
        this.data.rewardsBalanceNsfw = 0
        this.data.minimalUnlockDate = 0
        this.data.emergencyUnlockStatus = false
        this.data.depositFreeze = false
    }
}