<template>
  <header class="fixed top-0 left-0 w-full transluscent-white z-30">
    <div class="d-flex container lg:flex h-20 items-center justify-between">
        <a href="/">
            <img src="../assets/logo-nsfwpay-glow.png" alt="NSFW" style="width: 220px;">
        </a>
        <nav class="flex items-center space-x-12.5">
            <button
                v-if="storeState.connected" v-on:click.prevent="addTokenClick()" class="font-light hover:text-brand-pink-text">
              Display NSFW in your wallet
            </button>
            <form class="d-flex align-items-baseline">
              <button
                id="connectButtonWeb3"
                type="button"
                class="btn pink-gradient h-10.5"
                v-on:click="connect()"
                v-if="!storeState.connected"
              >Connect</button>
              <!-- <label id="accountAddressDiv" class="account-address">{{ storeState.selectedAccount }}</label> -->
              <button
                id="disconnectButtonWeb3"
                type="button"
                class="btn pink-gradient h-10.5"
                v-on:click="disconnect()"
                v-if="storeState.connected"
              >Disconnect</button>
            </form>
        </nav>
    </div>
</header>
</template>

<script>
import { store } from '../classes/Store'

export default {
  name: 'Navigation',
  data: function() {
      return {
        storeState: store.state
      }
  },
  methods: {
    connect: function() { this.$emit('connectClick');},
    disconnect: function() { this.$emit('disconnectClick'); },
    addTokenClick: function() { this.$emit('addTokenClick'); }
  }
}
</script>

<style lang="scss">
@import "../scss/index.scss";

.transluscent-white {
  background-color: rgba(255,255,255,0.95);
}

</style>

